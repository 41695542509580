import React from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const ButtonComponent = ({ color='primary', text = 'Please wait...', icon = '', type = "button", onClick, extraClass = '', paddingClass = 'px-4', isDisable = false , isHidden = false}) => {
    return (
        type === 'button' ?
            <CButton color={color} className={`cursor-pointer h-100 ${paddingClass} ${extraClass}`} onClick={onClick && onClick} disabled={isDisable} hidden={isHidden}>
                {(icon && text) ? (
                    <div className="d-flex gap-1 align-items-center">
                        <CIcon icon={icon} />
                        {text}
                    </div>
                ) : (
                    <>
                        {icon && <CIcon icon={icon} />}
                        {text}
                    </>
                )}
            </CButton>
            :
            <h4>Please wait...</h4>
    )
}

export default ButtonComponent