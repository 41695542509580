import * as Yup from 'yup';

const resetPassword = Yup.object().shape({
    newPassword: Yup.string()
        .required("Password shouldn't be empty!")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long"
        ),
    confirmPassword: Yup.string()
        .required("Confirm Password shouldn't be empty!")
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});


export default resetPassword;
