import { toast } from 'src/components/common/ToastComponent/toast';
import { callGet, callPost } from '../axios'
import { useNavigate } from 'react-router-dom';

export const doLogin = async ({ email, password, type = 'login' }) => {
    var bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('password', password);
    bodyFormData.append('type', type);

    return callPost(`/authenticate.php`, bodyFormData, '', 1).then((res) => {
        localStorage.setItem('userData', JSON.stringify(res?.data?.data?.userData))
        localStorage.setItem('token', JSON.stringify(res?.data?.data?.token))
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const doLogout = async (isAutoLogout = false) => {
    var token = JSON.parse(localStorage.getItem("token"));
    var bodyFormData = new FormData()
    bodyFormData.append('type', 'logout')
    bodyFormData.append('accessToken', token)
    return callPost(`/authenticate.php`, bodyFormData, token).then((res) => {
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        return res?.data?.data
    }).catch((err) => {
        if (isAutoLogout) {
            localStorage.removeItem('userData')
            localStorage.removeItem('token')
        }
        return Promise.reject(err)
    })
}

export const doForgotPassword = async ({ userName }) => {
    var bodyFormData = new FormData()
    bodyFormData.append('type', 'forgot')
    bodyFormData.append('userName', userName)
    return callPost(`/authenticate.php`, bodyFormData, '', 1).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const doResetPassword = async ({ newPassword, resetToken }) => {
    var bodyFormData = new FormData()
    bodyFormData.append('type', 'reset')
    bodyFormData.append('newPassword', newPassword)
    bodyFormData.append('resetToken', resetToken)
    return callPost(`/authenticate.php`, bodyFormData, '', 1).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const doChangePassword = async ({ newPassword, oldPassword, userId = null }) => {
    var token = JSON.parse(localStorage.getItem("token"));
    var bodyFormData = new FormData()
    bodyFormData.append('type', 'changePassword')
    bodyFormData.append('newPassword', newPassword)
    bodyFormData.append('oldPassword', oldPassword)
    bodyFormData.append('userId', userId)
    var apiPath = '/authenticate.php'
    if (userId) {
        apiPath = '/users.php'
    }
    return callPost(apiPath, bodyFormData, token).then((res) => {
        return res?.data?.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

// auto log out user after 24 hours when token expires
export const autoLogOutTimer = (navigate) => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && Object.values(userData).length > 0) {
        var token = JSON.parse(localStorage.getItem("token"));
        const decodedJwt = parseJwt(token);
        const expires = new Date(decodedJwt.exp * 1000);
        const timeout = expires.getTime() - Date.now();
        setTimeout(async () => {
            try {
                await doLogout(true)
            } catch {
                toast.show({
                    title: 'Warning',
                    description: 'Your session has been expired. Please login again.'
                })
                navigate('/')
            }
        }, timeout);
    }
}

const parseJwt = (token) => {
    try {
        return JSON.parse(window.atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};