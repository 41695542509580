import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLink,
  CRow,
  CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { doResetPassword } from 'src/services/actions/auth'
import { toast } from 'src/components/common/ToastComponent/toast'
import ButtonComponent from 'src/components/common/ButtonComponent'
import resetPassword from 'src/views/validation/resetPasswordValidation'
import { useFormik } from 'formik'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { resetToken } = useParams()
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (value, colName = 'newpassword') => {
    if (colName === 'newpassword') {
      setNewPassword(value)
    } else {
      setConfirmPassword(value)
    }
  }

  // const handleResetClick = async () => {
  //   if (!newPassword) {
  //     toast.show({
  //       title: 'Warning',
  //       description: 'New Password should not be empty!',
  //     })
  //     return
  //   }
  //   if (!confirmPassword) {
  //     toast.show({
  //       title: 'Warning',
  //       description: 'Confirm Password should not be empty!'
  //     })
  //     return
  //   }
  //   if (confirmPassword !== newPassword) {
  //     toast.show({
  //       title: 'Warning',
  //       description: 'Confirm Password must be same as new password!'
  //     })
  //     return
  //   }
  //   if (!resetToken) {
  //     toast.show({
  //       title: 'Warning',
  //       description: 'No reset token provided!'
  //     })
  //     return
  //   }
  //   if (newPassword && confirmPassword) {
  //     setIsLoading(true)
  //     try {
  //       const data = await doResetPassword({
  //         newPassword,
  //         resetToken
  //       })
  //       toast.show({
  //         title: 'Success',
  //         description: data?.message ? data?.message : 'Successfully reset password!',
  //       })
  //       navigate("/")
  //       setIsLoading(false)
  //       setNewPassword('')
  //       setConfirmPassword('')
  //     } catch (err) {
  //       toast.show({
  //         title: 'Warning',
  //         description: err?.response?.data?.data?.message ? err?.response?.data?.data?.message : 'Server error. Failed to reset password!',
  //       })
  //       setIsLoading(false)
  //     }
  //   }
  // }
  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (!resetToken) {
        toast.show({
          title: 'Warning',
          description: 'No reset token provided!'
        })
        return;
      }

      setIsLoading(true)
      const data = await doResetPassword({
        newPassword: values?.newPassword,
        resetToken
      })
      toast.show({
        title: 'Success',
        description: data?.message ? data?.message : 'Successfully reset password!',
      })
      navigate("/")
      setIsLoading(false)
      resetForm();

    } catch (err) {
      toast.show({
        title: 'Warning',
        description: err?.response?.data?.data?.message ? err?.response?.data?.data?.message : 'Server error. Failed to reset password!',
      })
      setIsLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: resetPassword,
    onSubmit: handleSubmit
  });



  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}  >
                    <h1>Reset Password</h1>
                    {formik.touched.newPassword && formik.errors.newPassword && <div className="text-danger mb-0 text-left">{formik.errors.newPassword}</div>}
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="New Password"
                        name='newPassword'
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </CInputGroup>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="text-danger mb-0 text-left">{formik.errors.confirmPassword}</div>}

                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Confirm Password"
                        name='confirmPassword'
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </CInputGroup>
                    <CRow className='align-items-center'>
                      <CCol xs={6}>
                        {isLoading ?
                          <ButtonComponent />
                          :
                        
                          <CButton className="btn-primary text-white" type='submit'>Reset Password</CButton>
                        }
                      </CCol>
                      <CCol xs={6} className="text-end">
                        <CLink
                          href="/"
                        >
                          Back to Login
                        </CLink>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
