import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CLink,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { doForgotPassword } from 'src/services/actions/auth'
import { toast } from 'src/components/common/ToastComponent/toast'
import ButtonComponent from 'src/components/common/ButtonComponent'

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [userName, setUserName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (value) => {
        setUserName(value)
    }

    const handleResetClick = async () => {
        if (userName) {
            setIsLoading(true)
            try {
                const data = await doForgotPassword({
                    userName
                })
                toast.show({
                    title: 'Success',
                    description: data?.message ? data?.message : 'Successfully reset password!',
                })
                navigate("/")
                setIsLoading(false)
            } catch (err) {
                toast.show({
                    title: 'Warning',
                    description: err?.response?.data?.data?.message ? err?.response?.data?.data?.message : 'No user found!',
                })
                setIsLoading(false)
            }
        } else {
            toast.show({
                title: 'Warning',
                description: 'Please enter username!',
            })
        }
    }

    return (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Forgot Password</h1>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput placeholder="Username" value={userName || ''} autoComplete="username" onChange={(e) => { handleChange(e.target.value) }} />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs={6}>
                                                {isLoading ?
                                                    <ButtonComponent />
                                                    :
                                                    <ButtonComponent text='Reset Password' onClick={handleResetClick} />
                                                }
                                            </CCol>
                                            <CCol xs={6} className="text-end">
                                                <CLink
                                                    href="/"
                                                >
                                                    Back to Login
                                                </CLink>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default ForgotPassword
